import { Ratio } from '@canalplus/mycanal-commons';
import { Carousel } from '@canalplus/mycanal-sharedcomponent';
import { Binder } from '@canalplus/one-navigation';
import classNames from 'classnames';
import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MIDDLEWARE_STRATE } from '../../helpers/oneNavigation/middleware';
import I18n from '../../lang';
import { displayTVModeSelector } from '../../store/slices/displayMode-selectors';
import type { ContentStrateV5, DisplayParameters } from '../../templates/LandingV5/data/formatter';
import styles from './CarrouselTemplate.css';
import CarrouselTemplateItem from './CarrouselTemplateItem';

type MakeCarouselItemsParameter = {
  contents: ContentStrateV5[];
  ratio: Ratio;
  hasBorderRadius: boolean;
  isTvDevice: boolean;
  isDesktopOnly?: boolean;
  isMobileOnly?: boolean;
};

const makeCarouselItems = ({
  contents,
  ratio,
  hasBorderRadius,
  isTvDevice,
  isDesktopOnly = false,
  isMobileOnly = false,
}: MakeCarouselItemsParameter) =>
  contents.map((content, index) => (
    <div
      className={classNames(styles.carrouselTemplate__cover, styles[`carrouselTemplate__cover--${ratio}`], 'carrousel')}
      key={content.contentID || index}
    >
      <CarrouselTemplateItem
        borderRadius={hasBorderRadius}
        content={content}
        isDesktopOnly={isDesktopOnly}
        isMobileOnly={isMobileOnly}
        ratio={ratio}
        isTvDevice={isTvDevice}
      />
    </div>
  ));

export type CarrouselTemplateProps = {
  contents: ContentStrateV5[];
  displayParameters?: DisplayParameters;
  onFocusable?: () => void;
};

function CarrouselTemplate({
  contents = [],
  displayParameters: { imageSize = 'normal' } = {},
  onFocusable,
}: CarrouselTemplateProps): JSX.Element | null {
  const { t } = I18n.useTranslation();
  const isTvDevice = useSelector(displayTVModeSelector);

  useEffect(() => {
    if (onFocusable && contents.length) {
      onFocusable();
    }
  }, [onFocusable, contents]);

  useEffect(() => {
    if (onFocusable && contents.length) {
      onFocusable();
    }
  }, [contents.length, onFocusable]);

  if (!contents.length) {
    return null;
  }

  const isImageSizeLarge = imageSize === 'large';
  const mobileRatio = isImageSizeLarge ? Ratio.Ratio43 : Ratio.Ratio169;
  const tabletRatio = isImageSizeLarge ? Ratio.Ratio169 : Ratio.Ratio166;

  const carouselItemsParams = {
    contents,
    isTvDevice,
    hasBorderRadius: isImageSizeLarge,
  } satisfies Omit<MakeCarouselItemsParameter, 'ratio'>;

  const mobileItems = makeCarouselItems({ ...carouselItemsParams, ratio: mobileRatio, isMobileOnly: true });
  const tabletItems = makeCarouselItems({ ...carouselItemsParams, ratio: tabletRatio, isDesktopOnly: true });

  return (
    <Binder middleware={MIDDLEWARE_STRATE}>
      <Carousel
        imageSize={imageSize}
        isCarrousel
        isTvDevice={isTvDevice}
        labels={{ next: t('HorizontalPaging.next'), previous: t('HorizontalPaging.previous') }}
        mobileItems={mobileItems}
        mobileRatio={mobileRatio}
        ratio={tabletRatio}
        showControls={!isTvDevice}
      >
        {tabletItems}
      </Carousel>
    </Binder>
  );
}

export default memo(CarrouselTemplate);
